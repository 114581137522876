






































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BotState, ProfitInterface } from '@/types';
import { BotStoreGetters } from '@/store/modules/ftbot';

import { formatPercent } from '@/shared/formatters';
import DateTimeTZ from '@/components/general/DateTimeTZ.vue';

const ftbot = namespace('ftbot');

@Component({ components: { DateTimeTZ } })
export default class BotStatus extends Vue {
  @ftbot.Getter [BotStoreGetters.version]: string;

  @ftbot.Getter [BotStoreGetters.profit]: ProfitInterface | {};

  @ftbot.Getter [BotStoreGetters.botState]?: BotState;

  formatPercent = formatPercent;
}
