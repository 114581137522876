





































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ForcebuyPayload } from '@/types';

const ftbot = namespace('ftbot');

@Component({})
export default class ForceBuyForm extends Vue {
  pair = '';

  price = null;

  $refs!: {
    form: HTMLFormElement;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action forcebuy!: (payload: ForcebuyPayload) => Promise<string>;

  created() {
    this.$bvModal.show('forcebuy-modal');
  }

  close() {
    this.$emit('close');
  }

  checkFormValidity() {
    const valid = this.$refs.form.checkValidity();

    return valid;
  }

  handleBuy(bvModalEvt) {
    // Prevent modal from closing
    bvModalEvt.preventDefault();
    // Trigger submit handler
    this.handleSubmit();
  }

  resetForm() {
    this.pair = '';
    this.price = null;
  }

  handleSubmit() {
    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      return;
    }
    // call forcebuy
    const payload: ForcebuyPayload = { pair: this.pair };
    if (this.price) {
      payload.price = Number(this.price);
    }
    this.forcebuy(payload);
    this.$nextTick(() => {
      this.$bvModal.hide('forcebuy-modal');
    });
  }
}
