


































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BalanceInterface } from '@/types';
import HideIcon from 'vue-material-design-icons/EyeOff.vue';
import ShowIcon from 'vue-material-design-icons/Eye.vue';

const ftbot = namespace('ftbot');

@Component({
  components: { HideIcon, ShowIcon },
})
export default class Balance extends Vue {
  @ftbot.Action getBalance;

  @ftbot.State balance!: BalanceInterface;

  hideSmallBalances = true;

  smallBalance = 0.00001;

  get balanceCurrencies() {
    if (!this.hideSmallBalances) {
      return this.balance.currencies;
    }

    return this.balance?.currencies?.filter((v) => v.est_stake >= this.smallBalance);
  }

  get tableFields() {
    return [
      { key: 'currency', label: 'Currency' },
      { key: 'free', label: 'Available', formatter: 'formatCurrency' },
      { key: 'est_stake', label: `in ${this.balance.stake}`, formatter: 'formatCurrency' },
    ];
  }

  mounted() {
    this.getBalance();
  }

  formatCurrency(value) {
    return value ? value.toFixed(5) : '';
  }
}
