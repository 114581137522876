




























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BlacklistPayload, BlacklistResponse } from '@/types';

const ftbot = namespace('ftbot');

@Component({})
export default class FTBotAPIPairList extends Vue {
  newblacklistpair = '';

  blackListShow = false;

  @ftbot.Action getWhitelist;

  @ftbot.Action getBlacklist;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action addBlacklist!: (payload: BlacklistPayload) => Promise<BlacklistResponse>;

  @ftbot.State whitelist!: string[];

  @ftbot.State blacklist!: string[];

  @ftbot.State pairlistMethods!: string[];

  created() {
    this.initBlacklist();
  }

  initBlacklist() {
    if (this.whitelist.length === 0) {
      this.getWhitelist();
    }
    if (this.blacklist.length === 0) {
      this.getBlacklist();
    }
  }

  addBlacklistPair() {
    if (this.newblacklistpair) {
      this.blackListShow = false;

      this.addBlacklist({ blacklist: [this.newblacklistpair] });
      this.newblacklistpair = '';
    }
  }
}
