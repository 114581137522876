














































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GridLayout, GridItem, GridItemData } from 'vue-grid-layout';

import TradeList from '@/components/ftbot/TradeList.vue';
import Performance from '@/components/ftbot/Performance.vue';
import BotControls from '@/components/ftbot/BotControls.vue';
import BotStatus from '@/components/ftbot/BotStatus.vue';
import Balance from '@/components/ftbot/Balance.vue';
import DailyStats from '@/components/ftbot/DailyStats.vue';
import FTBotAPIPairList from '@/components/ftbot/FTBotAPIPairList.vue';
import PairLockList from '@/components/ftbot/PairLockList.vue';
import PairSummary from '@/components/ftbot/PairSummary.vue';
import TradeDetail from '@/components/ftbot/TradeDetail.vue';
import ReloadControl from '@/components/ftbot/ReloadControl.vue';
import LogViewer from '@/components/ftbot/LogViewer.vue';
import DraggableContainer from '@/components/layout/DraggableContainer.vue';
import CandleChartContainer from '@/components/charts/CandleChartContainer.vue';

import { Lock, Trade } from '@/types';
import { BotStoreGetters } from '@/store/modules/ftbot';
import { TradeLayout, findGridLayout, LayoutGetters, LayoutActions } from '@/store/modules/layout';

const ftbot = namespace('ftbot');
const layoutNs = namespace('layout');

@Component({
  components: {
    GridLayout,
    GridItem,
    DraggableContainer,
    TradeList,
    Performance,
    BotControls,
    BotStatus,
    Balance,
    DailyStats,
    FTBotAPIPairList,
    PairLockList,
    PairSummary,
    TradeDetail,
    ReloadControl,
    LogViewer,
    CandleChartContainer,
  },
})
export default class Trading extends Vue {
  @ftbot.State detailTradeId!: number;

  @ftbot.Getter [BotStoreGetters.openTrades]!: Trade[];

  @ftbot.Getter [BotStoreGetters.closedTrades]!: Trade[];

  @ftbot.Getter [BotStoreGetters.allTrades]!: Trade[];

  @ftbot.Getter [BotStoreGetters.tradeDetail]!: Trade;

  @ftbot.Getter [BotStoreGetters.timeframe]!: string;

  @ftbot.Getter [BotStoreGetters.currentLocks]!: Lock[];

  @ftbot.State whitelist!: string[];

  @layoutNs.Getter [LayoutGetters.getTradingLayout]!: GridItemData[];

  @layoutNs.Action [LayoutActions.setTradingLayout];

  @layoutNs.Getter [LayoutGetters.getLayoutLocked]: boolean;

  get gridLayout(): GridItemData[] {
    return this.getTradingLayout;
  }

  get gridLayoutBotControls(): GridItemData {
    return findGridLayout(this.gridLayout, TradeLayout.botControls);
  }

  get gridLayoutMultiPane(): GridItemData {
    return findGridLayout(this.gridLayout, TradeLayout.multiPane);
  }

  get gridLayoutOpenTrades(): GridItemData {
    return findGridLayout(this.gridLayout, TradeLayout.openTrades);
  }

  get gridLayoutTradeHistory(): GridItemData {
    return findGridLayout(this.gridLayout, TradeLayout.tradeHistory);
  }

  get gridLayoutTradeDetail(): GridItemData {
    return findGridLayout(this.gridLayout, TradeLayout.tradeDetail);
  }

  get gridLayoutLogView(): GridItemData {
    return findGridLayout(this.gridLayout, TradeLayout.logView);
  }

  get gridLayoutChartView(): GridItemData {
    return findGridLayout(this.gridLayout, TradeLayout.chartView);
  }

  layoutUpdatedEvent(newLayout) {
    this.setTradingLayout(newLayout);
  }
}
